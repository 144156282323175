import React from 'react';
import App from './pages';
import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import SetGoogleAnalytics from './components/SetGoogleAnalytics';
import { GoogleAnalyticsProvider } from './hooks/GoogleAnalyticsContext';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const rootElement = document.getElementById('root');
ReactDOM.render(
  <StrictMode>
    <Router history={history}>
      <GoogleAnalyticsProvider>
        <App />
        <SetGoogleAnalytics />
      </GoogleAnalyticsProvider>
    </Router>
  </StrictMode>,
  rootElement
);
