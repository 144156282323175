import React from 'react';
import Button from '@material-ui/core/Button';

const PageNotFound = (props) => {
    return (
      <div style={{height: 400}}>
        <h1 style={{fontSize: 80, textAlign: 'center'}}>404</h1>
        <h1 style={{fontSize: 30, textAlign: 'center'}}>Page Not Found</h1>
        <div style={{marginTop: 50}}>
          <p style={{fontSize: 16, margin: 10}}>The page you're looking for is missing.</p>
          <p style={{fontSize: 16, margin: 10}}>If you typed the URL, check to make sure it is correct.</p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.history.goBack()}
            style={{margin: 10}}
          >Go Back
          </Button>
        </div>
      </div>
    );
  };

export default PageNotFound;